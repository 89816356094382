export const environment = {
    production: true,
    firebase: {
      apiKey: "AIzaSyBcd5r0nFGj49QWIZIkOvoxNmJ8RfJNuVI",
      authDomain: "labrador-f8560.firebaseapp.com",
      databaseURL: "https://labrador-f8560.firebaseio.com",
      projectId: "labrador-f8560",
      storageBucket: "labrador-f8560.appspot.com",
      messagingSenderId: "628090102143"
    },
    websocketHost:'wss://production.labrador.ai',
    orderApiBackend: "https://production.labrador.ai",
    accessToken: "e53e7a20711c270f8e890cfda68ef84319d94378",
    iTokenUrl: "https://fts-uat.cardconnect.com",
    // hostName: "https://order.text-order.com"
    hostName: "https://refer.pinvrf.com",
    referralHost: ["https://refer.pinvrf.com"],
    referralPinvrf: "https://refer.pinvrf.com"
  };  